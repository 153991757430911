<script>
export default {
  components: {},
  beforeCreate: function () {
    document.body.className = 'auth';
  }
};
</script>

<template>
  <div class="account-page">
    <slot />
  </div>
</template>
